import React from "react";

import DefaultLayout from "../../../layouts/default";

const DocL = () => (
  <DefaultLayout title="WiCare|Doc-L">
    <div className="white wicare">
      <div className="navContentSpacerHelper"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li>
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>

        <nav className="subNav2">
          <div className="subNav2Product">
            <div className="productImage">
              <img
                src="/assets/images/logos/WCN-Doc.svg"
                alt="WiCare|Doc Logo"
                width="40"
              />
            </div>
            <div className="productText">
              <span className="line">WiCare|Doc-L</span>
              <span className="line">
                <span className="is-visible-desktop">
                  &nbsp;für Langzeit-Institutionen
                </span>
                &nbsp;nach RAI-NH
              </span>
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links">
            <li className="is-active">
              <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                Übersicht
              </a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-l/mobile/index_rai">
                Mobile
              </a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-l/technik/index_rai">
                Technik
              </a>
            </li>

            <li>
              <a href="/dokumentationsloesungen/wicare-doc-l/referenzen/index_rai">
                Referenzen
              </a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-l/downloads/index_rai">
                Downloads
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <section className="mainBanner">
          <div className="wrapper has-padding-top has-padding-bottom">
            <div className="bannerFlex">
              <div>
                <img
                  className="logo-product"
                  src="/assets/images/logos/wicaredoc-weiss.svg"
                />
              </div>
              <div className="bannerPadding">
                <h1>WiCare|Doc-L</h1>
                <h3 className="padding-17-bottom">
                  Die elektronische <br />
                  Bewohnerdokumentation nach RAI-NH
                </h3>
                <p className="hideMobile">
                  WiCare|Doc-L ist die professionelle Bewohnerdokumentation für
                  Alters- und Pflegeheime, welche die Pflegebedarfsabklärung
                  nach RAI-NH unterstützt.
                </p>
              </div>
            </div>
          </div>
          <div className="background">
            <img
              className="js-img-size-from-parent"
              src="https://wigasoft-prod.imgix.net/backgrounds/bkg-wcd.png?q=50&w=1200&h=400"
            />
          </div>
          <div className="wrapper verySmallStaticPadding-top is-background-color-white">
            <p className="mobileBlue">
              WiCare|Doc-L ist die professionelle Bewohnerdokumentation für
              Alters- und Pflegeheime, welche die Pflegebedarfsabklärung nach
              RAI-NH unterstützt.
            </p>
          </div>
        </section>

        <div className="wrapper">
          <section className="has-padding-top has-padding-bottom">
            <h1>WiCare|Doc-L</h1>
            <h4 className="smallStaticPadding-bottom">
              Die elektronische Bewohnerdokumentation nach RAI-NH
            </h4>
            <p>
              WiCare|Doc-L ist die professionelle Bewohnerdokumentation für
              Alters- und Pflegeheime, welche die Pflegebedarfsabklärung nach
              RAI-NH unterstützt. Durch den Einsatz von WiCare|Doc-L wird jeder
              Aspekt des pflegerischen Handelns verbessert. Dies betrifft vor
              allem die individuelle Planung, die Dokumentation und Beurteilung
              der Pflege. Die pflegerische Haltung wird transparent und
              beurteilbar. WiCare|Doc-L ist eine flexible Softwarelösung, die
              sich den betriebsspezifischen Bedürfnissen anpasst.
            </p>
          </section>
        </div>

        <div className="wrapper mobile-no-padding">
          <section className="cContainer has-margin-bottom is-background-color-blue is-color-white">
            <h3 className="smallStaticPadding-bottom">WiCare|Doc-L</h3>
            <ul className="fliesstext2">
              <li>
                bildet den Pflegeprozess vom Eintritt bis zur Verlegung
                vollständig ab und verknüpft dessen Elemente sinnvoll
              </li>
              <li>
                bietet eine präzise Übersicht über den Status aller Aufgaben (zu
                tun/erledigt), die Termine aller Bewohner, Fragen an den Arzt
              </li>
              <li>
                berücksichtigt im Assessment die RAI-NH MDS Struktur, bzw. die
                Problembereiche
              </li>
              <li>unterstützt die RAI-NH Beobachtungsphase</li>
              <li>
                beinhaltet Fokusassessments zur Erfassung von Schmerz,
                Sturzrisiko, Dekubitusrisiko, etc. und bietet statistische
                Auswertungen
              </li>
              <li>
                analysiert das Assessment und den Pflegebericht und schlägt
                Pflegediagnosen (NANDA-I) vor
              </li>
              <li>
                verknüpft die Pflegeklassifikationen NANDA-I, NIC (Nursing
                Interventions Classification) und Doenges-Ziele vollumfänglich
              </li>
              <li>
                erfasst die Leistungen (Material, Medikamente) auf Basis der
                dokumentierten Tätigkeiten
              </li>
              <li>
                bietet Grundlagen für eine einwandfreie Argumentation über die
                erbrachten und verrechenbaren Leistungen
              </li>
              <li>
                bietet eine komplette Wunddokumentation inklusive Fotoverlauf
              </li>
              <li>
                erlaubt interdisziplinäre Zugriffe auf die Behandlungsprozesse
              </li>
              <li>
                generiert automatisierte und individualisierbare Übergabe- und
                Austrittsberichte
              </li>
              <li>
                verfügt über standardisierte Schnittstellen zu den gängigen
                ERP-Systemen
              </li>
              <li>
                berücksichtigt inder (Weiter-)Entwicklung aktuelle
                pflegewissenschaftliche Erkenntnisse wie Expertenstandards und
                Forschungsergebnisse
              </li>
              <li>
                ist dank eines integrierten Ausfall-Konzepts jederzeit verfügbar
              </li>
            </ul>
          </section>
        </div>

        <div className="wrapper">
          <section>
            <h3>Nutzen</h3>
            <div className="cAccordeonContainer">
              <div className="tab cAccordeonTab">
                <input id="tab-nutzen-one" type="checkbox" name="tabs" />
                <label for="tab-nutzen-one">
                  <h5>Betreuung</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <h6 className="smallStaticPadding-bottom">
                      Die ganzheitliche Betreuung wird Realität
                    </h6>
                    <p className="fliesstext">
                      Die individuelle Betreuung und Begleitung älterer Menschen
                      ist anspruchsvoll und die zur Verfügung stehende
                      Pflegezeit kostbar. WiCare|Doc-L unterstützt den gesamten
                      Pflegeprozess durch gezielte Vereinfachungen der
                      Dokumentationsprozesse und gewährleistet gleichzeitig
                      einen schnellen Überblick über alle Pflegeaktivitäten.
                    </p>
                    <p className="fliesstext smallStaticPadding-bottom">
                      Automatisierte Prozesse im Hintergrund erfassen
                      administrative Daten und stellen diese für die Verrechnung
                      zur Verfügung.
                    </p>
                  </div>
                  <div className="cContainer is-background-color-grey smallStaticMargin-bottom">
                    <h3 className="smallStaticPadding-bottom">WiCare|Doc-L</h3>
                    <ul className="fliesstext">
                      <li>
                        stellt der Pflegefachperson alle Informationen für ihre
                        professionelle Planung, Durchführung und Auswertung zur
                        Verfügung
                      </li>
                      <li>
                        erleichtert durch übersichtliche Darstellung das
                        Einlesen und Kennenlernen eines neuen Bewohners und die
                        Dokumentation bei Veränderungen
                      </li>
                      <li>
                        berücksichtigt im Assessment die RAI-NH MDS Struktur,
                        bzw. die Problembereiche
                      </li>
                      <li>unterstützt die RAI-NH Beobachtungsphase</li>
                      <li>
                        zeigt die Fragen an den Arzt und alle Pflegemassnahmen
                        an
                      </li>
                      <li>
                        bietet in der Kalenderfunktion einen Überblick über alle
                        Termine der Bewohnerinnen. Somit gehen keine Ereignisse
                        vergessen, egal ob es der Coiffeur-Besuch oder die
                        externe Physiotherapie ist
                      </li>
                      <li>
                        beinhaltet nach Expertenstandards aufgebaute
                        Fokusassessments zur Erfassung von Schmerz, Sturz,
                        Delir, Norton, etc. Diese können mit hausinternen
                        Standards gekoppelt werden
                      </li>
                      <li>
                        analysiert Texteinträge im Assessment sowie im
                        Pflegebericht und schlägt Pflegediagnosen nach dem
                        PES-/PRF-Format (NANDA-I) mit korrekten Zielen und
                        Massnahmen vor
                      </li>
                      <li>
                        stellt Vitalwerte tabellarisch oder grafisch in einer
                        Kurvenansicht dar
                      </li>
                      <li>
                        alarmiert bei abnormen Vitalwerten, Bilanzen,
                        Dosierungsüberschreitungen etc., oder wenn z.B.
                        monatliche Gewichtsmessungen fällig sind
                      </li>
                      <li>
                        bietet eine komplette Wunddokumentation inklusive
                        Fotoübersicht
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tab cAccordeonTab">
                <input id="tab-nutzen-two" type="checkbox" name="tabs" />
                <label for="tab-nutzen-two">
                  <h5>Behandlungsteam</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <h6 className="smallStaticPadding-bottom">
                      Die Therapie zeigt Wirkung
                    </h6>
                    <p className="fliesstext smallStaticPadding-bottom">
                      Physiotherapie, Ergotherapie, Ernährungsberatung,
                      Psychologen, Sozialdienst und andere Berufsgruppen
                      erhalten mit WiCare|Doc-L ein Instrument zur
                      professionellen und strukturierten Dokumentation der
                      Bewohnersituation. Mittels dieser Daten werden
                      Therapiepläne erstellt und umgesetzt. Die automatisierte
                      Generierung von Berichten und Rapporten bei Verlegungen
                      oder Austritten vereinfacht den administrativen Aufwand um
                      ein Vielfaches.
                    </p>
                  </div>
                  <div className="cContainer is-background-color-grey smallStaticMargin-bottom">
                    <h3 className="smallStaticPadding-bottom">WiCare|Doc-L</h3>
                    <ul className="fliesstext">
                      <li>
                        fokussiert alle therapeutischen Tätigkeiten konsequent
                        auf die optimale Betreuung des Bewohners und ermöglicht
                        so eine hohe Behandlungssicherheit
                      </li>
                      <li>
                        stellt den Therapeuten angepasste Informationen für die
                        professionelle Planung, Durchführung und Auswertung
                        ihrer Therapien zur Verfügung
                      </li>
                      <li>
                        bietet strukturierte Fokusassessments, wie Tinetti,
                        Timed "up and go" Test etc. zur systematischen
                        Situationseinschätzung und Beurteilung des
                        Gesundheitszustandes des Bewohners und Einleitung der
                        individuellen Therapie
                      </li>
                      <li>
                        unterstützt die systematische Beurteilung von
                        Zielvorgaben und Interventionen, um mit dem Bewohner
                        realistische Behandlungsergebnisse zu erreichen
                      </li>
                      <li>
                        bietet ein Set von angepassten Leistungen in der
                        Standardplanung
                      </li>
                      <li>
                        generiert automatisiert Berichte, Übergabe- und
                        Austrittsprotokolle sowie Anträge für Hilfsmittel,
                        inklusive definierter Inhalte. Diese beinhalten den
                        Status und einen Verlauf der Therapie
                      </li>
                      <li>
                        bietet für verschiedene klinische Settings sinnvolle
                        Benutzeroberflächen
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tab cAccordeonTab">
                <input id="tab-nutzen-three" type="checkbox" name="tabs" />
                <label for="tab-nutzen-three">
                  <h5>Ärzteschaft</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <h6 className="smallStaticPadding-bottom">
                      Die Behandlung steht im Fokus
                    </h6>
                    <p className="fliesstext">
                      Im medizinischen Alltag den Überblick über den Verlauf der
                      Diagnosen und Verordnungen zu bewahren, ist Teil einer
                      erfolgreichen Behandlung. WiCare|Doc-L bietet
                      strukturierte Instrumente zur professionellen Erfassung
                      und Dokumentation anamnestischer Daten und
                      Statuserhebungen.
                    </p>
                    <p className="fliesstext smallStaticPadding-bottom">
                      Eine genau dem eigenen Informationsbedürfnis angepasste
                      Oberfläche erleichtert die Kommunikation sowie die
                      Verordnung. Die automatisierte Generierung von Berichten
                      und Rapporten reduziert den administrativen Aufwand um ein
                      Vielfaches.
                    </p>
                  </div>
                  <div className="cContainer is-background-color-grey smallStaticMargin-bottom">
                    <h3 className="smallStaticPadding-bottom">WiCare|Doc-L</h3>
                    <ul className="fliesstext">
                      <li>
                        fokussiert alle diagnostischen und therapeutischen
                        Tätigkeiten konsequent auf die optimale Behandlung des
                        Bewohners und ermöglicht so eine hohe
                        Behandlungssicherheit
                      </li>
                      <li>
                        bietet ortsunabhängig einen sicheren Zugriff auf die
                        Bewohnerdaten
                      </li>
                      <li>
                        beinhaltet anamnestische Sets für die verschiedenen
                        medizinischen Settings
                      </li>
                      <li>ermöglicht differenzierte Statuserhebungen</li>
                      <li>
                        stellt Überwachungsdaten in einer Übersicht zur
                        Verfügung und bietet direkten Zugriff auf die Daten
                        anderer Berufsgruppen
                      </li>
                      <li>
                        bietet anpassbare Verordnungssets für Eintritte oder
                        spezifische Untersuchungen, (Vorbereitung, Durchführung,
                        Nachbereitung)
                      </li>
                      <li>
                        gibt Rückmeldung zum Status der Verordnungen
                        (durchgeführt oder offen)
                      </li>
                      <li>
                        unterstützt die systematische Beurteilung von
                        Zielvorgaben und Interventionen, um mit dem Bewohner
                        realistische Behandlungsergebnisse zu erreichen
                      </li>
                      <li>
                        bietet eine komplette Wunddokumentation inklusive
                        Fotoübersicht
                      </li>
                      <li>
                        alarmiert bei abnormen Vitalwerten, Bilanzen,
                        Dosierungsüberschreitungen
                      </li>
                      <li>
                        generiert automatisiert Berichte, Rezepte, Übergabe- und
                        Austrittsprotokolle, inklusive definierter Inhalte.
                        Diese beinhalten den Status und einen Verlauf der
                        Behandlung
                      </li>
                      <li>
                        bietet für verschiedene klinische Settings angepasste
                        Benutzeroberflächen
                      </li>
                      <li>
                        ermöglicht die Dateneinsicht aller an der Behandlung
                        beteiligten Fachpersonen und verbessert so die
                        Kommunikation
                      </li>
                      <li>
                        schützt die Bewohnerdaten vor dem Zugriff durch
                        Unbefugte
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tab cAccordeonTab">
                <input id="tab-nutzen-four" type="checkbox" name="tabs" />
                <label for="tab-nutzen-four">
                  <h5>Management</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <h6 className="smallStaticPadding-bottom">
                      Strukturierte Daten auf einen Blick
                    </h6>
                    <p className="fliesstext smallStaticPadding-bottom">
                      Kostendruck, abnehmende Ressourcen und ein sich dynamisch
                      entwickelndes Gesundheitswesen stellen hohe Anforderungen
                      an das Management. Die Organisation und die Leitung des
                      Heimbetriebs erfordern nebst qualifiziertem Personal vor
                      allem Informationen über die relevanten Steuerungsdaten,
                      wie Personalbedarf (grade and skill mix), Bettenauslastung
                      und Pflegestufe. WiCare|Doc-L stellt qualitativ
                      hochwertige Steuerungsdaten zur Verfügung. Dabei
                      berücksichtigt WiCare|Doc-L die hohen Ansprüche an eine
                      professionelle Pflegedokumentation ebenso wie die
                      effizienten Arbeitsprozesse der Mitarbeiterinnen.
                    </p>
                  </div>
                  <div className="cContainer is-background-color-grey smallStaticMargin-bottom">
                    <h3 className="smallStaticPadding-bottom">WiCare|Doc-L</h3>
                    <ul className="fliesstext">
                      <li>
                        unterstützt die Einstufung des Pflegebedarfs nach RAI-NH
                      </li>
                      <li>
                        beinhaltet die Planung, Auswertung und Verrechnung von
                        Pflegeleistungen
                      </li>
                      <li>liefert Daten für eine gezielte Ressourcenplanung</li>
                      <li>
                        unterstützt das Qualitätsmanagement mit hinterlegten
                        Qualitätsstandards
                      </li>
                      <li>
                        gewährleistet Kontinuität und Nachvollziehbarkeit der
                        Pflegeleistungen
                      </li>
                      <li>bietet Beweissicherung in rechtlichen Situationen</li>
                      <li>liefert die Datengrundlage zur Abrechnung</li>
                      <li>
                        liefert Statistiken zur Sturzhäufigkeit, Dekubitus,
                        Mangelernährung etc.
                      </li>
                      <li>
                        unterstützt die geforderten medizinischen
                        Qualitätsindikatoren
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tab cAccordeonTab">
                <input id="tab-nutzen-five" type="checkbox" name="tabs" />
                <label for="tab-nutzen-five">
                  <h5>Informatik</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <h6 className="smallStaticPadding-bottom">
                      standardisiert und normiert
                    </h6>
                    <p className="fliesstext smallStaticPadding-bottom">
                      WiCareDoc-L passt sich in die vorgegebene IT-Landschaft
                      ein. Die Orientierung an gängigen Standards wie ALIS und
                      HL7 hilft bei der einfachen Integration von
                      Dritt-Systemen. Die Kompatibilität mit den neusten Windows
                      Server- und Client-Betriebssystemen sowie den aktuellen
                      Versionen von Microsoft SQL Server und Datenbanksysteme
                      erlauben den Betrieb stabiler und ausbaufähiger Systeme,
                      die sich nahtlos in Standard-Netzwerke einbinden lassen.
                    </p>
                  </div>
                  <div className="cContainer is-background-color-grey smallStaticMargin-bottom">
                    <h3 className="smallStaticPadding-bottom">WiCare|Doc-L</h3>
                    <ul className="fliesstext">
                      <li>
                        gewährleistet dank dem integrierten Ausfallkonzept
                        WiCare|DocNA Zugriff auf die wichtigsten Daten
                      </li>
                      <li>
                        kann auf mehreren Servern redundant installiert werden
                        und erhöht damit die Ausfallsicherheit
                      </li>
                      <li>
                        verfügt mit WiCare|Admin über ein einfach zu bedienendes
                        Konfigurationsmodul
                      </li>
                      <li>
                        stellt mit WiCare|Gate ein flexibel konfigurierbares
                        Schnittstellenmodul bereit
                      </li>
                      <li>
                        basiert auf einer strukturiert aufgebauten Datenbank mit
                        einfach lesbarem Datenmodell
                      </li>
                      <li>
                        unterstützt den Export sämtlicher Daten und
                        gewährleistet dadurch die Weiterverwendbarkeit
                      </li>
                      <li>
                        lässt sich mittels den URI- und DDE-Standards aus einer
                        Drittapplikation kontextsensitiv aufrufen
                      </li>
                      <li>
                        liefert über WiCare|SOAP Informationen für Drittsysteme
                        in Form von Webservices ad hoc
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="has-padding-top has-padding-bottom">
            <h3 className="smallStaticPadding-bottom">
              Komponenten von WiCare|Doc-L
            </h3>
            <p className="fliesstext">
              Die Bewohnerdokumentation WiCare|Doc-L besteht aus verschiedenen
              Modulen - das heisst, Sie bestimmen, welche Bestandteile in Ihrem
              Betrieb zum Einsatz kommen. Kurz gesagt, Sie erhalten mit
              WiCare|Doc-L eine Lösung, die Ihre betriebsspezifischen
              Bedürfnisse berücksichtigt und entsprechend konfiguriert ist.
            </p>
            <br />
            <p className="fliesstext">
              Schwerpunkt RAI - Beobachtungsphase mit RAI-NH-Kategorien, Pflege
              und Verlaufsbericht mit RAI-NH-Kategorien, Tagesstruktur.
            </p>
            <div className="accordeonContainer">
              <div className="tab">
                <input id="tab-one" type="checkbox" name="tabs" />
                <label for="tab-one">
                  <h5>Stationsmanager</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <div className="column one-third has-spacerMobilePadding-right">
                      <a
                        href="/assets/images/screenshots/l-rai/Stationsmanager_1_Statistik.png"
                        data-lightbox="l-rai1"
                        data-actual-title="Stationsmanager - Sturzstatistik"
                      >
                        <img src="/assets/images/screenshots/l-rai/Stationsmanager_1_Statistik.png" />
                      </a>
                      <div className="is-hidden">
                        <a
                          href="/assets/images/screenshots/l-rai/Stationsmanager_2_Kalender.png"
                          data-lightbox="l-rai1"
                          data-actual-title="Stationsmanager - Kalender"
                        >
                          <img src="/assets/images/screenshots/l-rai/Stationsmanager_2_Kalender.png" />
                        </a>
                        <a
                          href="/assets/images/screenshots/l-rai/Stationsmanager_3_Leistungsbericht.png"
                          data-lightbox="l-rai1"
                          data-actual-title="Stationsmanager - Leistungsübersicht"
                        >
                          <img src="/assets/images/screenshots/l-rai/Stationsmanager_3_Leistungsbericht.png" />
                        </a>
                      </div>
                    </div>
                    <div className="column two-third has-spacerMobilePadding-right">
                      <p className="fliesstext2">
                        Fokussiert auf die Darstellung der gesamten Abteilung.
                        Die Einträge aller Bewohner werden im Überblick
                        dargestellt.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-two" type="checkbox" name="tabs" />
                <label for="tab-two">
                  <h5>Cockpit</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <div className="column one-third has-spacerMobilePadding-right">
                      <a
                        href="/assets/images/screenshots/l-rai/Cockpit_1_Einlesen_RAI.png"
                        data-lightbox="l-rai2"
                        data-actual-title="Cockpit - Übersicht"
                      >
                        <img src="/assets/images/screenshots/l-rai/Cockpit_1_Einlesen_RAI.png" />
                      </a>
                    </div>
                    <div className="column two-third has-spacerMobilePadding-right">
                      <p className="fliesstext2">
                        Wird nach der Anmeldung als Startseite angezeigt, dient
                        als Informationsbeschaffungsinstrument und enthält alle
                        relevanten Auskünfte pro Bewohner.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-three" type="checkbox" name="tabs" />
                <label for="tab-three">
                  <h5>Bewohnerinformationen</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <div className="column one-third has-spacerMobilePadding-right">
                      <a
                        href="/assets/images/screenshots/l-rai/Bewohnerinformation_1_Bewohnerangaben.png"
                        data-lightbox="l-rai3"
                        data-actual-title="Bewohnerinformationen - Bewohner"
                      >
                        <img src="/assets/images/screenshots/l-rai/Bewohnerinformation_1_Bewohnerangaben.png" />
                      </a>
                      <div className="is-hidden">
                        <a
                          href="/assets/images/screenshots/l-rai/Bewohnerinformation_2_Kontakte.png"
                          data-lightbox="l-rai3"
                          data-actual-title="Bewohnerinformationen - Kontaktperson"
                        >
                          <img src="/assets/images/screenshots/l-rai/Bewohnerinformation_2_Kontakte.png" />
                        </a>
                        <a
                          href="/assets/images/screenshots/l-rai/Bewohnerinformation_3_Kalender.png"
                          data-lightbox="l-rai3"
                          data-actual-title="Bewohnerinformationen - Kalender"
                        >
                          <img src="/assets/images/screenshots/l-rai/Bewohnerinformation_3_Kalender.png" />
                        </a>
                      </div>
                    </div>
                    <div className="column two-third has-spacerMobilePadding-right">
                      <p className="fliesstext2">
                        Strukturierte Übersicht aller Informationen zum
                        Bewohner.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-four" type="checkbox" name="tabs" />
                <label for="tab-four">
                  <h5>Assessment und Fokusassessments</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <div className="column one-third has-spacerMobilePadding-right">
                      <a
                        href="/assets/images/screenshots/l-rai/Assessment_2_NNN.png"
                        data-lightbox="l-rai4"
                        data-actual-title="Assessment - NNN"
                      >
                        <img src="/assets/images/screenshots/l-rai/Assessment_2_NNN.png" />
                      </a>
                      <div className="is-hidden">
                        <a
                          href="/assets/images/screenshots/l-rai/Assessment_3_ATL.png"
                          data-lightbox="l-rai4"
                          data-actual-title="Assessment - ATL"
                        >
                          <img src="/assets/images/screenshots/l-rai/Assessment_3_ATL.png" />
                        </a>
                        <a
                          href="/assets/images/screenshots/l-rai/Assessment_4_ATL_Verlauf.png"
                          data-lightbox="l-rai4"
                          data-actual-title="Assessment - ATL Verlauf"
                        >
                          <img src="/assets/images/screenshots/l-rai/Assessment_4_ATL_Verlauf.png" />
                        </a>
                        <a
                          href="/assets/images/screenshots/l-rai/Assessment_5_Resumee.png"
                          data-lightbox="l-rai4"
                          data-actual-title="Assessment - Resumee"
                        >
                          <img src="/assets/images/screenshots/l-rai/Assessment_5_Resumee.png" />
                        </a>
                        <a
                          href="/assets/images/screenshots/l-rai/Assessment_6_Einschaetzung.png"
                          data-lightbox="l-rai4"
                          data-actual-title="Assessement - Fokus-Assessments"
                        >
                          <img src="/assets/images/screenshots/l-rai/Assessment_6_Einschaetzung.png" />
                        </a>
                      </div>
                    </div>
                    <div className="column two-third has-spacerMobilePadding-right">
                      <p className="fliesstext2">
                        Verschiedene Assessments wie z.B. NNN, AEDL, ATL sowie
                        alle gängigen Einschätzungen und Scores, welche im
                        Alltag benötigt werden.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-five" type="checkbox" name="tabs" />
                <label for="tab-five">
                  <h5>Ärztliche Anamnese</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Systematische Dokumentation des aktuellen
                      Gesundheitszustandes, der Vorgeschichte und der
                      Lebensumstände des Bewohners aus ärztlicher Sicht.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-six" type="checkbox" name="tabs" />
                <label for="tab-six">
                  <h5>Diagnosen</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <div className="column one-third has-spacerMobilePadding-right">
                      <a
                        href="/assets/images/screenshots/l-rai/med_Diagnosen_Diagnosen.png"
                        data-lightbox="l-rai10"
                        data-actual-title="Medizinische Diagnosen - Diagnosen"
                      >
                        <img src="/assets/images/screenshots/l-rai/med_Diagnosen_Diagnosen.png" />
                      </a>
                      <div className="is-hidden">
                        <a
                          href="/assets/images/screenshots/l-rai/pflegerische_Diagnose_1_uebersicht.png"
                          data-lightbox="l-rai10"
                          data-actual-title="Pflegediagnosen - Übersicht"
                        >
                          <img src="/assets/images/screenshots/l-rai/pflegerische_Diagnose_1_uebersicht.png" />
                        </a>
                        <a
                          href="/assets/images/screenshots/l-rai/pflegerische_Diagnose_2_Etiologie.png"
                          data-lightbox="l-rai10"
                          data-actual-title="Pflegediagnosen - Etiologien"
                        >
                          <img src="/assets/images/screenshots/l-rai/pflegerische_Diagnose_2_Etiologie.png" />
                        </a>
                        <a
                          href="/assets/images/screenshots/l-rai/pflegerische_Diagnose_3_Ziel.png"
                          data-lightbox="l-rai10"
                          data-actual-title="Pflegediagnosen - Pflegeziele"
                        >
                          <img src="/assets/images/screenshots/l-rai/pflegerische_Diagnose_3_Ziel.png" />
                        </a>
                        <a
                          href="/assets/images/screenshots/l-rai/pflegerische_Diagnose_4_Handlungen.png"
                          data-lightbox="l-rai10"
                          data-actual-title="Pflegediagnosen - Pflegeinterventionen"
                        >
                          <img src="/assets/images/screenshots/l-rai/pflegerische_Diagnose_4_Handlungen.png" />
                        </a>
                      </div>
                    </div>
                    <div className="column two-third has-spacerMobilePadding-right">
                      <p className="fliesstext2">
                        Ärztliche nach ICD und Pflegediagnosen nach NANDA-I sind
                        nach dem PES/PRF Format aufgebaut und beinhalten Ziele
                        nach Doenges.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-seven" type="checkbox" name="tabs" />
                <label for="tab-seven">
                  <h5>Verordnung/Planung</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <div className="column one-third has-spacerMobilePadding-right">
                      <a
                        href="/assets/images/screenshots/l-rai/Verordnung_1_Medikamente.png"
                        data-lightbox="l-rai5"
                        data-actual-title="Verordnung/Planung - Medikamente"
                      >
                        <img src="/assets/images/screenshots/l-rai/Verordnung_1_Medikamente.png" />
                      </a>
                      <div className="is-hidden">
                        <a
                          href="/assets/images/screenshots/l-rai/Verordnung_2_Interventionen.png"
                          data-lightbox="l-rai5"
                          data-actual-title="Verordnung/Planung - Interventionen"
                        >
                          <img src="/assets/images/screenshots/l-rai/Verordnung_2_Interventionen.png" />
                        </a>
                      </div>
                    </div>
                    <div className="column two-third has-spacerMobilePadding-right">
                      <p className="fliesstext2">
                        Beinhaltet ärztliche Verordnungen (Medikation,
                        Anordnungen), pflegerische und therapeutische
                        Massnahmen. Die pflegerischen Massnahmen können
                        standardisiert durch einen Katalog erfasst werden.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-eight" type="checkbox" name="tabs" />
                <label for="tab-eight">
                  <h5>Überwachung</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <div className="column one-third has-spacerMobilePadding-right">
                      <a
                        href="/assets/images/screenshots/l-rai/ueberwachung_1_Schmerz_nach_ECPA.png"
                        data-lightbox="l-rai6"
                        data-actual-title="Überwachung - Einschätzungen nach ECPA"
                      >
                        <img src="/assets/images/screenshots/l-rai/ueberwachung_1_Schmerz_nach_ECPA.png" />
                      </a>
                      <div className="is-hidden">
                        <a
                          href="/assets/images/screenshots/l-rai/ueberwachung_2_Vitalwerte.png"
                          data-lightbox="l-rai6"
                          data-actual-title="Überwachung - Messung"
                        >
                          <img src="/assets/images/screenshots/l-rai/ueberwachung_2_Vitalwerte.png" />
                        </a>
                        <a
                          href="/assets/images/screenshots/l-rai/ueberwachung_3_Kurve_Vitalwerte.png"
                          data-lightbox="l-rai6"
                          data-actual-title="Überwachung - Grafik"
                        >
                          <img src="/assets/images/screenshots/l-rai/ueberwachung_3_Kurve_Vitalwerte.png" />
                        </a>
                      </div>
                    </div>
                    <div className="column two-third has-spacerMobilePadding-right">
                      <p className="fliesstext2">
                        Kurvendarstellung der gängigen Überwachungs-Sets wie
                        z.B. Vitalwerte, Schmerz und Bilanz. Über 60 Erhebungen
                        sind inbegriffen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-nine" type="checkbox" name="tabs" />
                <label for="tab-nine">
                  <h5>Massnahmenplan</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <div className="column one-third has-spacerMobilePadding-right">
                      <a
                        href="/assets/images/screenshots/l-rai/Massnahmenplan_1_Medikamente.png"
                        data-lightbox="l-rai7"
                        data-actual-title="Massnahmenplan - Medikamente"
                      >
                        <img src="/assets/images/screenshots/l-rai/Massnahmenplan_1_Medikamente.png" />
                      </a>
                      <div className="is-hidden">
                        <a
                          href="/assets/images/screenshots/l-rai/Massnahmenplan_1_RAI.png"
                          data-lightbox="l-rai7"
                          data-actual-title="Massnahmenplan - Interventionen"
                        >
                          <img src="/assets/images/screenshots/l-rai/Massnahmenplan_1_RAI.png" />
                        </a>
                        <a
                          href="/assets/images/screenshots/l-rai/Massnahmenplan_2_Interventionen.png"
                          data-lightbox="l-rai7"
                          data-actual-title="Massnahmenplan - Interventionen 2"
                        >
                          <img src="/assets/images/screenshots/l-rai/Massnahmenplan_2_Interventionen.png" />
                        </a>
                      </div>
                    </div>
                    <div className="column two-third has-spacerMobilePadding-right">
                      <p className="fliesstext2">
                        Übersicht der geplanten und durchgeführten medizinischen
                        und pflegerischen Massnahmen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-ten" type="checkbox" name="tabs" />
                <label for="tab-ten">
                  <h5>Berichte/Protokolle</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <div className="column one-third has-spacerMobilePadding-right">
                      <a
                        href="/assets/images/screenshots/l-rai/Bericht_1_neuer_Eintrag.png"
                        data-lightbox="l-rai8"
                        data-actual-title="Berichte/Protokolle - Neuer Eintrag"
                      >
                        <img src="/assets/images/screenshots/l-rai/Bericht_1_neuer_Eintrag.png" />
                      </a>
                      <div className="is-hidden">
                        <a
                          href="/assets/images/screenshots/l-rai/Bericht_2_uebersicht.png"
                          data-lightbox="l-rai8"
                          data-actual-title="Berichte/Protokolle - Verlaufsbericht"
                        >
                          <img src="/assets/images/screenshots/l-rai/Bericht_2_uebersicht.png" />
                        </a>
                        <a
                          href="/assets/images/screenshots/l-rai/Bericht_3_Sturzprotkoll.png"
                          data-lightbox="l-rai8"
                          data-actual-title="Berichte/Protokolle - Ereignisse"
                        >
                          <img src="/assets/images/screenshots/l-rai/Bericht_3_Sturzprotkoll.png" />
                        </a>
                        <a
                          href="/assets/images/screenshots/l-rai/Bericht_4_Dekubituseinschaetzung.png"
                          data-lightbox="l-rai8"
                          data-actual-title="Berichte/Protokolle - Dekubitus"
                        >
                          <img src="/assets/images/screenshots/l-rai/Bericht_4_Dekubituseinschaetzung.png" />
                        </a>
                      </div>
                    </div>
                    <div className="column two-third has-spacerMobilePadding-right">
                      <p className="fliesstext2">
                        Chronologischer Verlaufsbericht des aktuellen Befindens
                        der Bewohner in Freitext, Pflegediagnosen werden
                        vorgeschlagen. Protokolle für verschiedene
                        Berufsgruppen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-eleven" type="checkbox" name="tabs" />
                <label for="tab-eleven">
                  <h5>Wund- und Stomadokumentation</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <div className="column one-third has-spacerMobilePadding-right">
                      <a
                        href="/assets/images/screenshots/l-rai/Wunddokumentation_1_neue_Wunde.png"
                        data-lightbox="l-rai9"
                        data-actual-title="Wund- und Stomadokumentation - Wunde"
                      >
                        <img src="/assets/images/screenshots/l-rai/Wunddokumentation_1_neue_Wunde.png" />
                      </a>
                      <div className="is-hidden">
                        <a
                          href="/assets/images/screenshots/l-rai/Wunddokumentation_2_Wundbeurteilung.png"
                          data-lightbox="l-rai9"
                          data-actual-title="Wund- und Stomadokumentation - Protokoll"
                        >
                          <img src="/assets/images/screenshots/l-rai/Wunddokumentation_2_Wundbeurteilung.png" />
                        </a>
                        <a
                          href="/assets/images/screenshots/l-rai/Wunddokumentation_3_Wundfoto.png"
                          data-lightbox="l-rai9"
                          data-actual-title="Wund- und Stomadokumentation - Wundfoto"
                        >
                          <img src="/assets/images/screenshots/l-rai/Wunddokumentation_3_Wundfoto.png" />
                        </a>
                      </div>
                    </div>
                    <div className="column two-third has-spacerMobilePadding-right">
                      <p className="fliesstext2">
                        Protokollierung von Wunden und deren Behandlung, inkl.
                        Zuordnung der entsprechenden Bilder.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-thirteen" type="checkbox" name="tabs" />
                <label for="tab-thirteen">
                  <h5>Leistungserfassung</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <div className="column one-third has-spacerMobilePadding-right">
                      <a
                        href="/assets/images/screenshots/l-rai/Leistungserfassung_1_Materialerfassung.png"
                        data-lightbox="l-rai10"
                        data-actual-title="Leistungserfassung - Einzelleistungen"
                      >
                        <img src="/assets/images/screenshots/l-rai/Leistungserfassung_1_Materialerfassung.png" />
                      </a>
                    </div>
                    <div className="column two-third has-spacerMobilePadding-right">
                      <p className="fliesstext2">
                        Übersicht der erbrachten Leistungen (Tätigkeiten,
                        Material/Medikamente), die für die Weiterverarbeitung in
                        der Abrechnung oder im Controlling benötigt werden.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-fourteen" type="checkbox" name="tabs" />
                <label for="tab-fourteen">
                  <h5>Archivierung</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Übergabe der relevanten Dokumente in ein Universalarchiv
                      für die Langzeitablage.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="wrapper">
          <div className="bContainer blue has-margin-bottom">
            <div className="row">
              <div className="column one-third text-centered smallStaticPadding-left smallStaticPadding-right has-padding-top">
                <img src="/assets/images/logos/WS_EPD-Logo_10_2018.png" />
              </div>
              <div className="column two-third">
                <h5>Anbindung elektronisches Patientendossier (EPD)</h5>
                <p>
                  Als Anbieter und Betreiber von Primärsystemen für die
                  Patientendokumentation ist es für uns eine
                  Selbstverständlichkeit, unsere Kunden auf dem Weg in die
                  Zukunft mit eHealth und dem elektronischen Patientendossier
                  (EPD) zu begleiten.
                </p>
              </div>
            </div>
          </div>
        </div>

            <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                    WiCare|Doc-L nach RAI-NH
                  </a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2025 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>

              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </DefaultLayout>
);

export default DocL;
